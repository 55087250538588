<template>
  <div class="content">
    <div class="breadcrumb">
      <div style="text-align: right">
        <el-button type="primary" @click="handleGoBack">返回</el-button>
      </div>
    </div>
    <div class="task-item">
      <div class="title-text fw600">任务流程</div>
      <task-step :step="step" :stepList="stepList" />
    </div>
    <div class="task-item">
      <div class="title-text fw600 mb26">追缴对象</div>
      <div class="recovery-object">
        <el-row>
          <el-col :span="10">
            <div>
              <span class="fc666">汇总范围:</span>
              <span>
                {{ detailData.createdTime }}&nbsp;
                <template v-if="detailData.recoverStartTimes == 0"> 前已出场全部订单 </template>
                <template v-else>
                  前已出场达&nbsp;{{ detailData.recoverStartTimes }}&nbsp;小时的订单
                </template>
              </span>
            </div>
          </el-col>
          <el-col :span="7">
            <div><span class="fc666">欠费金额:</span>{{ detailData.taskDebtMoney || "" }}</div>
          </el-col>
          <el-col :span="7">
            <div><span class="fc666">欠费订单数:</span>{{ detailData.taskDebtOrderNum || 0 }}</div>
          </el-col>
        </el-row>
      </div>
      <div class="vehicle-detail">
        <el-row class="mb16">
          <el-col :span="6">
            <div><span class="fc666 label-box">车辆:</span>{{ detailData.carNum || 0 }}辆</div>
          </el-col>
          <el-col :span="6">
            <div>
              <span class="fc666 label-box">可触达车辆:</span
              >{{ detailData.touchableCarNum || 0 }}辆
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <span class="fc666 label-box">追缴订单总数:</span
              >{{ detailData.recoverCount || 0 }}个订单
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <span class="fc666 label-box">可触达订单数:</span
              >{{ detailData.touchableCount || 0 }}个订单
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <div>
              <span class="fc666 label-box">追缴金额:</span
              >{{ detailData.recoverMoney / 100 || "0.00" }}元
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <span class="fc666 label-box">可触达金额:</span
              >{{ detailData.touchableMoney / 100 || "0.00" }}元
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <span class="fc666 label-box">预估触达率:</span>{{ detailData.touchablePercent || 0 }}
            </div>
          </el-col>
        </el-row>
      </div>
      <task-table :detailType="detailType" :id="id" @back="handleGetDetail" />
    </div>
    <div class="task-item">
      <div class="title-text fw600 mb26">基础信息</div>
      <div class="recovery-object">
        <el-row class="mb16">
          <el-col :span="8">
            <div><span class="fc666">追缴编号:</span>{{ detailData.recoverCode }}</div>
          </el-col>
          <el-col :span="8">
            <div><span class="fc666">追缴负责人:</span>{{ detailData.taskManagerName }}</div>
          </el-col>
          <el-col :span="8">
            <div><span class="fc666">发起时间:</span>{{ detailData.createdTime }}</div>
          </el-col>
        </el-row>
        <el-row class="mb16">
          <el-col :span="8">
            <div><span class="fc666">审核人:</span>{{ detailData.reviewerName || "-" }}</div>
          </el-col>
          <el-col :span="8">
            <div><span class="fc666">审核时间:</span>{{ detailData.revieTime || "-" }}</div>
          </el-col>
          <el-col :span="8">
            <div><span class="fc666">追缴时间:</span>{{ detailData.recoverTime || "-" }}</div>
          </el-col>
        </el-row>
        <el-row v-if="type == 1" class="mb16">
          <el-col :span="24">
            <div>
              <span class="fc666">追缴短信:</span>
              <el-select
                v-model="template"
                placeholder="请选择"
                @change="setChange"
                :disabled="detailType != 1"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.templateName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <span class="fc999">短信模板取自运营营销-短信管理</span>
              <!-- <p class="fc999 mr4">
                亲爱的 {【车牌号】}
                车主，您好，目前您的停车产生欠费，请尽快通过{车主移动端名车}进行车辆缴费，谢谢合作。
              </p> -->
              <p class="fc999 mr4">{{ detailData.content || "" | setSms }}</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div v-if="type == 2" class="task-item">
      <div class="title-text fw600 mb26">任务类型</div>
      <div class="phone-box">
        <el-radio-group v-model="phoneType" :disabled="detailType == '2' || detailType == '3'">
          <el-radio :label="0">人工外呼</el-radio>
          <el-radio :label="1">智能外呼</el-radio>
        </el-radio-group>
        <span v-if="phoneType == '1'"
          >外呼模板：亲爱的 {【车牌号】}
          车主，您好，目前您的停车产生欠费，请尽快通过{车主移动端名车}进行车辆缴费，谢谢合作。</span
        >
      </div>
    </div>
    <div v-if="detailType == '1' || detailType == '3'" class="btn-group">
      <el-button
        class="sms-btn mr20"
        v-if="type == '1' && (detailType == '1' || detailType == '3')"
        @click="smsDialogVisible = true"
        >短信预览</el-button
      >
      <template v-if="detailType == '3'">
        <el-button class="mr20" :loading="submitLoading" @click="handleSubmit('2')">驳回</el-button>
        <el-button type="primary" :loading="submitLoading" @click="handleSubmit('1')"
          >通过</el-button
        >
      </template>
      <el-button v-else type="primary" :loading="submitLoading" @click="handleSubmit('0')"
        >提交</el-button
      >
    </div>
    <div class="taskDialog">
      <el-dialog title="短信预览" :visible.sync="smsDialogVisible" width="30%">
        <div class="sms-box">
          <!-- 亲爱的【辽AEE001】车主，您好，目前您的停车产生欠费，请尽快通过沈阳泊车APP进行车辆缴费，谢谢合作。 -->
          {{ detailData.content || "" | setSms }}
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import taskStep from "@/components/task/taskStep";
import taskTable from "@/components/task/taskTable";
export default {
  data() {
    return {
      phoneType: "",
      smsDialogVisible: false,
      id: "",
      type: "", // 1: 短信  2：电话
      detailType: "", // 编辑：1  查看：2  审核：3
      step: 2,
      previewStr: "",
      stepList: [],
      detailData: {},
      submitLoading: false,
      template: "",
      options: [],
      taskList: [
        {
          label: "创建中",
          value: "1",
        },
        {
          label: "待提交",
          value: "2",
        },
        {
          label: "待审核",
          value: "3",
        },
        {
          label: "已驳回",
          value: "4",
        },
        {
          label: "已超时",
          value: "5",
        },
        {
          label: "生效中",
          value: "6",
        },
        {
          label: "已完结",
          value: "7",
        },
      ],
    };
  },
  filters: {
    setSms(val) {
      // 暂时固定参数
      // eslint-disable-next-line no-template-curly-in-string
      return val.replace("${plateNumber}", "【辽AEE001】").replace("${debtMoney}", "12.98");
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.detailType = this.$route.query.detailType || "2";
    // if (this.type == "1") {
    //   this.previewStr = "短信预览";
    // } else if (this.type == "2") {
    //   this.previewStr = "电话预览";
    // } else {
    //   this.previewStr = "预览";
    // }
    this.handleGetDetail();
  },
  methods: {
    handleGoBack() {
      this.$router.push({
        path: this.$route.query.back,
      });
    },
    setChange(val) {
      this.detailData.content = this.options.find((item) => {
        return item.id == val;
      }).content;
      this.smsTemplateId = val;
    },
    // 下拉模板
    getSpinner(smsTemplateId) {
      console.log("下拉模板");
      this.$axios.get("/acb/2.0/memberSmsTask/getMemberSmsTemplate").then((res) => {
        this.options = res.value;
        const item = res.value.find((item) => {
          return item.id == smsTemplateId;
        });
        this.template = item.templateName;
        this.detailData.content = item.content;
      });
    },
    handleGetDetail() {
      let opt = {
        method: "get",
        url: "/acb/2.0/recoverTask/detail",
        data: {
          id: this.id,
        },
        success: (res) => {
          if (res.state == 0) {
            this.detailData = res.value;
            this.phoneType =
              res.value.taskRecoverType === 0 || res.value.taskRecoverType === 1
                ? res.value.taskRecoverType
                : "";
            const taskStatus = res.value.taskStatus || "";
            this.handleStepInit(taskStatus, res.value); // 步骤条处理

            this.getSpinner(res.value.smsTemplateId);
          } else {
            this.$alert(res.msg, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    handleStepInit(taskStatus, data) {
      let stepData = [
        {
          label: "1",
          title: "创建任务",
          user: data.taskManagerName || "",
          date: data.createdTime || "",
        },
        {
          label: "2",
          title: "提交任务",
          lineText: "待提交",
          user: data.taskSubmitorName || "",
          date: data.taskTime || "",
        },
        {
          label: "3",
          title: "审核任务",
          lineText: "待审核",
          user: data.reviewerName || "",
          date: data.revieTime || "",
        },
      ];
      if (taskStatus == 1 || taskStatus == 2 || taskStatus == 3) {
        this.step = taskStatus;
      } else {
        this.step = 4;
        // const taskArr = this.taskList.filter(item => {  // 显示第4状态
        //   return item.value == taskStatus
        // })
        // if (taskArr.length) {
        //   stepData.push({
        //     label: "4",
        //     title: taskArr[0].label,
        //     lineText: "",
        //     user: '',
        //     date: '',
        //   })
        // } else {
        //   stepData.push({
        //     label: "4",
        //     title: '状态异常',
        //     lineText: "",
        //     user: '',
        //     date: '',
        //   })
        // }
      }
      this.stepList = stepData;
    },
    handleSubmit(type) {
      if (this.type == 2 && this.phoneType === "") {
        return this.$alert("请选择任务类型", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      }
      // type:  0-提交  1-通过  2-驳回
      if (type != 0) {
        let tips = "";
        if (type == 1) {
          tips = "通过";
        } else if (type == 2) {
          tips = "驳回";
        }
        this.$confirm(`确认${tips}该项追缴？`, this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.handleExamineRequest(type);
          })
          .catch(() => {});
      } else {
        this.handleRequest();
      }
    },
    handleRequest() {
      this.submitLoading = true;
      let params = {
        taskId: this.id,
      };
      if (this.type == 2) {
        params.taskRecoverType = this.phoneType;
      }
      if (this.type == 1) {
        params.smsTemplateId = this.smsTemplateId;
      }
      let opt = {
        method: "post",
        url: "/acb/2.0/recoverTask/submit",
        data: params,
        success: (res) => {
          this.submitLoading = false;
          if (res.state == 0) {
            this.$message({
              type: "success",
              message: "提交成功",
            });
            this.handleGoBack();
          } else {
            this.$alert(res.msg, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
          this.loading = false;
        },
      };
      this.loading = true;
      this.$request(opt);
    },
    handleExamineRequest(type) {
      this.submitLoading = true;
      let opt = {
        method: "post",
        url: "/acb/2.0/recoverTaskApproval/approval",
        data: {
          taskId: this.id,
          approvalStatus: type,
        },
        success: (res) => {
          this.submitLoading = false;
          if (res.state == 0) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.handleGoBack();
          } else {
            this.$alert(res.msg, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
          this.loading = false;
        },
      };
      this.loading = true;
      this.$request(opt);
    },
  },
  components: {
    taskStep,
    taskTable,
  },
};
</script>
<style lang="stylus" scoped>
.content
  font-size 14px
  color #333
  .breadcrumb
    position absolute
    right 31px
    z-index 999
    button
      position relative
      z-index 9999
  .fc666
    color #666
    margin-right 10px
  .fc999
    color #999
    margin-left 10px
  .mr4
    margin: 20px 0 0 68px;
  .mb26
    margin-bottom 26px
  .recovery-object
    margin-bottom 24px
  .label-box
    width 90px
    display inline-block
  .mb16
    margin-bottom 20px
  .vehicle-detail
    width 100%
    padding 16px
    box-sizing border-box
    background-color #F3F7FF
  .fw700
    font-weight bold
  .fw400
    font-weight normal
.task-item
  padding 16px
  box-sizing border-box
  background-color #fff
  border-radius 8px
  margin-bottom 16px
.fw600
  font-weight 600
.title-text
  position relative
  font-size 14px
  color #2F2F51
  padding-left 12px
  // margin: 8px 0;
  margin-bottom 8px
  &:before
    content ''
    position absolute
    left 0
    top 50%
    transform translateY(-50%)
    width 4px
    height 15px
    background #0C66FF
.content
  width 100%
  padding 16px 0
  .task-box
    width 100%
.btn-group
  margin-top 24px
  text-align center
  .sms-btn
    border-color #527BFF
    color #527BFF
  .mr20
    margin-right 20px
  button
    width 100px
.phone-box
  line-height 24px
  span
    font-size 14px
    color #333
/deep/
  .sms-box
    line-height 26px
  .el-radio:first-child
    margin-right 64px
  .el-radio:nth-child(2)
    margin-right 34px
  .el-dialog__body
    padding-top 0
  .el-dialog
    top 22vh
</style>
