<template>
  <div class="step-content">
    <div class="step-box">
      <div v-for="(item, i) in stepList" :key="i" class="step-item fl" :class="stepList.length > 3 ? 'select' : ''">
        <div
          class="step-item fl"
          :class="{
            'success-box': step > item.label,
            'select-box': step == item.label,
          }"
        >
          <el-divider v-if="item.label != 1" class="fl">
            {{ step == item.label ? item.lineText : "" }}
          </el-divider>
          <div class="fl main">
            <div v-if="step > item.label" class="icon-box">
              <i class="el-icon-check"></i>
            </div>
            <div v-else class="icon-box">{{ item.label }}</div>
            <h3>{{ item.title }}</h3>
            <div class="detail-box">
              <p class="user">{{ item.user }}</p>
              <p class="date-box">{{ item.date }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1
    },
    stepList: {
      type: Array,
      default: []
    },
  },
  data() {
    return {
    };
  },
  created() {
    console.log(this.step)
    console.log(this.stepList)
  }
};
</script>
<style lang="stylus" scoped>
.fl {
  float: left;
}

.step-box {
  overflow: hidden;
  display: inline-block;
  margin: 0 auto;
  padding-bottom: 2px;
}

.step-content {
  width: 100%;
  padding: 15px 0 8px;
  text-align: center;

  /deep/ {
    .el-divider--horizontal {
      width: 13vw;
      margin-top: 16px;
    }
    .select .el-divider--horizontal {
      width: 11vw;
    }

    .el-icon-check {
      font-size: 20px;
      color: #1890FF;
      font-weight: 700; 
    }

    .el-divider {
      background-color: #527BFF;
    }
    .el-divider__text {
      color: #527BFF;
    }
  }

  .step-item {
    color: #999999;
    font-size: 16px;
    overflow: hidden;
    padding-bottom: 2px;
    &:nth-child(4) {
      .main {
        margin-left: 38px;
      }
    }
    .icon-box {
      width: 32px;
      height: 32px;
      line-height: 32px;
      border: 1px solid #999999;
      border-radius: 50%;
      margin: 0 auto;
      margin-bottom: 18px;
    }

    &.select-box {
      color: #527BFF;

      .icon-box {
        color: #fff;
        background: #527BFF;
        border: 1px solid #527BFF;
      }
    }

    &.success-box {
      .icon-box {
        border: 1px solid #1890FF;
      }

      h3 {
        color: #333333;
      }
    }
    .detail-box {
      margin-top: 10px;
      text-align: center;
      color: #333333;
      font-size: 16px;
      .user {
        margin-bottom: 8px;
      }
    }
  }
}
</style>
