<template>
  <div class="task-tabel-box">
    <div class="search-box">
      <el-form
        v-if="detailType == '1'"
        :inline="true"
        label-position="right"
        :model="formInline"
        ref="formInline"
        class="demo-form-inline"
      >
        <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
          <el-autocomplete
            size="12"
            valueKey="plateNumber"
            class="inline-input"
            v-model="formInline.plateNumber"
            :fetch-suggestions="querySearchAsync"
            placeholder="请输入内容"
            :trigger-on-focus="false"
            @select="handleSelect"
            @change="handleChange"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item :label="$t('searchModule.arrears')">
          <el-input
            type="text"
            v-model="formInline.recoverMoneyStart"
            style="width: 120px"
            placeholder="请输入"
            maxlength="7"
            oninput="value=value.replace(/[^\d]/g,'')"
          ></el-input
          ><span class="mglr8">至</span>
          <el-input
            type="text"
            v-model="formInline.recoverMoneyEnd"
            style="width: 120px"
            placeholder="请输入"
            maxlength="7"
            oninput="value=value.replace(/[^\d]/g,'')"
          ></el-input
          ><span class="mgl8">元</span>
        </el-form-item>
        <el-form-item label="欠费订单数">
          <el-input
            type="text"
            v-model="formInline.recoverCountStart"
            style="width: 120px"
            placeholder="请输入"
            maxlength="7"
            oninput="value=value.replace(/[^\d]/g,'')"
          ></el-input
          ><span class="mglr8">至</span>
          <el-input
            type="text"
            v-model="formInline.recoverCountEnd"
            style="width: 120px"
            placeholder="请输入"
            maxlength="7"
            oninput="value=value.replace(/[^\d]/g,'')"
          ></el-input
          ><span class="mgl8">次</span>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Accessibility')">
          <el-select v-model.trim="formInline.status" size="15">
            <el-option
              v-for="(item, index) in selectList"
              :label="item.label"
              :value="item.value"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <div class="search-btn">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="
              pageNum = 1;
              searchData();
            "
            :loading="loading"
            >{{ $t('button.search') }}</el-button
          >
          <el-button type="primary" icon="el-icon-delete" @click="resetForm()"
            >{{ $t('button.reset') }}</el-button
          >
        </div>
      </el-form>
    </div>
    <div class="tableWrapper">
      <el-table
        
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
      >
        <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
        <el-table-column
          v-for="item in tableCols"
          :key="item.prop"
          :prop="item.prop"
          align="center"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
        <el-table-column v-if="detailType == '1'" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="!scope.row.d"
              type="text"
              @click="handleDelect(scope.row)"
              >删除</el-button
            >
            <template v-else>
              <el-button type="text" disabled>已删除</el-button>
              <el-button type="text" @click="handleRevoke(scope.row)"
                >撤销</el-button
              >
            </template>
          </template>
        </el-table-column>
      </el-table>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { setIndex } from "@/common/js/public.js";
import { compareSize } from "@/common/js/utils.js"
export default {
  props: {
    detailType: {
      type: String,
      default: "2",
    },
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      total: 10,
      pageSize: 15,
      pageNum: 1,
      tableData: [],
      formInline: {
        plateNumber: "",
        carId: "",
        status: "",
        recoverMoneyStart: "",
        recoverMoneyEnd: "",
        recoverCountStart: "",
        recoverCountEnd: "",
      },
      selectList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "是",
          value: "1",
        },
        {
          label: "否",
          value: "0",
        },
      ],
      tableCols: [
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
          formatter: (row, column) => {
            if (row.plateNumber.indexOf("无") != -1) {
              return "无牌车";
            } else {
              return row.plateNumber;
            }
          },
        },
        {
          prop: "recoverMoney",
          label: "欠费追缴金额",
          width: "",
          formatter: (row, column) => {
            if (row.recoverMoney) {
              if (row.recoverMoney >= 0) {
                return Number(row.recoverMoney / 100).toFixed(2) + '元';
              } else {
                return row.recoverMoney + '元';
              }
            } else {
              return "0.00元";
            }
          },
        },
        {
          prop: "recoverCount",
          label: "欠费订单数",
          width: "",
        },
        {
          prop: "memberPhone",
          label: "会员电话",
          width: "",
        },
        {
          prop: "policePhone",
          label: "公安接口电话",
          width: "",
        },
        {
          prop: "thirdPhone",
          label: "三方导入电话",
          width: "",
        },
        {
          prop: "selfPhone",
          label: "近日自主停车",
          width: "",
        },
        {
          prop: "statusDesc",
          label: "可触达性",
          width: "",
          formatter: (row, column) => {
            if (row.statusDesc) {
              return row.statusDesc
            } else {
              return '否'
            }
          }
        },
      ],
      areaIds: "",
    };
  },
  created() {
    this.searchData();
  },
  methods: {
    handleChange(value) {
      if (!value) {
        this.formInline.carId = ''
      }
    },
    // 搜索
    searchData() {
      if (!this.formInline.carId) {
        this.formInline.plateNumber = ''
      }
      const { recoverMoneyStart, recoverMoneyEnd, recoverCountStart, recoverCountEnd } = this.formInline
      if (!compareSize(recoverMoneyStart, recoverMoneyEnd, '欠费金额')) return;
      if (!compareSize(recoverCountStart, recoverCountEnd, '欠费订单数')) return;
      let opt = {
        method: "get",
        url: "/acb/2.0/recoverTaskDetail/list",
        data: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          plateNumber: this.formInline.plateNumber,
          carId: this.formInline.carId,
          status: this.formInline.status,
          recoverMoneyStart: this.formInline.recoverMoneyStart ? this.formInline.recoverMoneyStart * 100 : '',
          recoverMoneyEnd: this.formInline.recoverMoneyEnd ? this.formInline.recoverMoneyEnd * 100 : '',
          recoverCountStart: this.formInline.recoverCountStart,
          recoverCountEnd: this.formInline.recoverCountEnd,
          taskId: this.id
        },
        success: (res) => {
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.msg, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
          this.loading = false;
        },
      };
      this.loading = true;
      this.$request(opt);
    },
    showLog(name, start, end) {
      if (start && end) {
        let time = new Date(end) - new Date(start);
        time = time / (24 * 60 * 60 * 1000);
        if (time >= 31) {
          this.$alert(name + "查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    handleDeletRequest(row, type) {
      const params = {
        taskId: this.id,
        carId: row.carId,
        isDelete: type
      };
      let tips = '删除成功!'
      if (type == 1) {
        tips = '撤销成功!'
      }
      let opt = {
        method: "post",
        url: "/acb/2.0/recoverTaskDetail/delete",
        data: params,
        success: (res) => {
          if (res.state == 0) {
            this.$message({
              type: "success",
              message: tips,
            });
            this.$emit('back', {})
            this.searchData();
          } else {
            this.$alert(res.msg, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    handleDelect(row) {
      this.$confirm("确定要删除该记录?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.handleDeletRequest(row, 0);
        })
        .catch(() => {});
    },
    handleRevoke(row) {
      this.$confirm("确定要撤销该记录?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.handleDeletRequest(row, 1);
        })
        .catch(() => {});
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        this.formInline.carId = "";
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    handleSelect(item) {
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searchData();
    },
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    resetForm() {
      this.formInline = {
        plateNumber: "",
        carId: "",
        operationId: "",
        parkName: "",
        startTime: "",
        endTime: "",
        status: "",
        recoverMoneyStart: "",
        recoverMoneyEnd: "",
        recoverCountStart: "",
        recoverCountEnd: "",
      };
    },
  },
};
</script>
<style lang="stylus" scoped>
.task-tabel-box {
  margin-top: 24px;

  .mglr8 {
    margin: 0 8px;
  }

  .mgl8 {
    margin-left: 8px;
  }

  .search-btn {
    text-align: right;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;
  }

  .tableWrapper {
    margin-top: 16px;

    /deep/ {
      .el-table thead {
        color: #000;
      }

      .el-table th.el-table__cell {
        background-color: #F3F7FF;
        height: 54px;
      }
    }
  }
}
</style>
